import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Toast } from 'vant';
import URL from "./utils/url";
import { post, get, postOther } from "./utils/http";
const app = createApp(App)
// directive(app)
app.config.globalProperties = {
  URL,
  $get: get,
  $post: post,
  $postOther: postOther,
}
app.use(router).use(Toast).mount('#app')
