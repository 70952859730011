import Cryptojs from "crypto-js";
import moment from "moment"
export default {
  toFixed(num, k) {
    return parseFloat(num).toFixed(k)
  },
  //  加密方法
  aes(word) {
    const key = Cryptojs.enc.Utf8.parse("4063EF9C087ECCFCF0ACDD22C127F445");
    const iv = Cryptojs.enc.Utf8.parse("0123456789ABCDEF");
    let srcs = Cryptojs.enc.Utf8.parse(word);
    let encrypted = Cryptojs.AES.encrypt(srcs, key, {
      iv: iv,
      mode: Cryptojs.mode.CBC,
      padding: Cryptojs.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
  },
  //  解密方法
  //  data：要解密的字符串
  //  key：加密因子
  //  iv：偏移量
  ads(data) {
    const key = Cryptojs.enc.Utf8.parse('4063EF9C087ECCFCF0ACDD22C127F445'); // 加密因子
    const iv = Cryptojs.enc.Utf8.parse('0123456789ABCDEF'); // 偏移量
    let encryptedHexStr = Cryptojs.enc.Hex.parse(data);

    let str = Cryptojs.enc.Base64.stringify(encryptedHexStr)

    let decrypted = Cryptojs.AES.decrypt(str, key, {
      iv: iv,
      mode: Cryptojs.mode.CBC, // AES加密模式
      padding: Cryptojs.pad.Pkcs7 // 填充:  pkcs7 paddin
    })
    return decrypted.toString(Cryptojs.enc.Utf8).toString()
  },
  sign(obj) {
    let apiSecret = "test";
    if (process.env.NODE_ENV == 'production') {
      apiSecret = "ZZ7lcg4O7vbd";
    }
    var keys = [];
    for (let key in obj) {
      keys.push(key);
    }

    keys.sort();
    let strTemp = '';
    for (let k in keys) {
      let value = obj[keys[k]];
      if ('string' != typeof value) {
        value = JSON.stringify(value);
      }
      strTemp += keys[k] + '=' + value + '&';
    }
    strTemp = strTemp.replace(/&$/, ""); //去掉最后的逗号
    return Cryptojs.HmacSHA256(strTemp, apiSecret).toString();
  },
  GetRandomNum(min, max) {
    var range = max - min;
    var rand = Math.random();
    return min + Math.round(rand * range);
  },
  //保留2位小数
  decal(val) {
    if (val === undefined || val === '' || val === null) {
      return 0.00
    }
    let str = val + ''
    let strVal = val
    if (str.indexOf('.') !== -1) {
      // let reg = RegExp("/^-?\d+.?\d{1," + n + "}/",'g')
      let reg = /^-?\d+.?\d{1,2}/
      // str 就是截取之后的字符串
      strVal = str.match(reg)
      if (isNaN(strVal)) {
        strVal = 0
      }
    }
    return Number(strVal).toFixed(2)
  },
  //保留4位小数
  decal4(val) {
    if (val === undefined || val === '' || val === null) {
      return 0.00
    }
    let str = val + ''
    let strVal = val
    if (str.indexOf('.') !== -1) {
      // let reg = RegExp("/^-?\d+.?\d{1," + n + "}/",'g')
      let reg = /^-?\d+.?\d{1,4}/
      // str 就是截取之后的字符串
      strVal = str.match(reg)
      if (isNaN(strVal)) {
        strVal = 0
      }
    }
    return Number(strVal).toFixed(4)
  },
  //保留6位小数
  decal6(val) {
    if (val === undefined || val === '' || val === null) {
      return 0.00
    }
    let str = val + ''
    let strVal = val
    if (str.indexOf('.') !== -1) {
      // let reg = RegExp("/^-?\d+.?\d{1," + n + "}/",'g')
      let reg = /^-?\d+.?\d{1,6}/
      // str 就是截取之后的字符串
      strVal = str.match(reg)
      if (isNaN(strVal)) {
        strVal = 0
      }
    }
    return Number(strVal).toFixed(6)
  },
  //当前时间
  nowTime(TimeZone) {
    let date = new Date()
    let utcTime = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
    switch (TimeZone) {
      case 'beijing':
        return Date.parse(moment().utcOffset(8))
      default:
        return Date.parse(moment().utcOffset(8))
    }
  },
  //人数格式
  peopleFormat(num) {
    if (Number(num) >= 0) {
      return Number(num).toLocaleString()
    } else {
      return num
    }
  },
  //节流
  throttle(fn, delay) {
    let flag = true
    return () => {
      if (flag) {
        setTimeout(() => {
          fn()
          flag = true
        }, delay)
      }
      flag = false
    }
  },
  //打开网址
  openUrl(url) {
    if (url) window.open(url.replace(/\s+/g, ''), '_blank')
  },
  isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
		return flag;
  }
};